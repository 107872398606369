import { Button } from '@material-ui/core'
import { Person as PersonIcon, InsertDriveFile as InsertDriveFileIcon } from '@material-ui/icons'
import { format } from 'date-fns'
import { differenceInYears } from 'date-fns/esm'
import * as R from 'ramda'
import { memo, useCallback, useEffect, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as patientenActions from '../../actions/customers'
import { selectTerminProps } from '../../actions/dates'
import * as dateienActions from '../../actions/files'
import { fields } from '../../components/CustomerForm'
import PatientText from '../../components/PatientText/PatientText'
import { GERMAN_DATE_SHORT_YEAR_FNS, GERMAN_DATE_TIME_FNS } from '../../constants/dateFormats'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import { AutocompleteField } from '../../shared/components/AutocompleteField'
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField'
import { FemaleIcon, MaleIcon, TransgenderIcon } from '../../shared/components/Gender'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import InputField from '../../shared/components/InputField'
import { sApiServer } from '../../shared/utils/auth'
import { plan } from '../../shared/utils/constants'
import { buildLeistungenString } from '../../utils/dates'
import { isReadOnly } from '../../utils/helpers'
import AnamnesisListView from '../AnamnesisListView/AnamnesisListView'
import DatesListView from '../DatesListView/DatesListView'
import FileUploadWithLightbox from '../FileUploadWithLightbox/FileUploadWithLightbox'
import HVOListView from '../HVOListView/HVOListView'
import TherapyReportsListView from '../TherapyReportsListView/TherapyReportsListView'
import pictureBlankSrc from './picture-blank.png'
import { sGetInitialValues } from './selectors'
import { AddNewLink, Link, StyledCustomerDetails } from './StyledCustomerDetails'

const genderIcons = {
  male: <MaleIcon />,
  female: <FemaleIcon />,
  trans: <TransgenderIcon />,
}

const CustomerDetails = ({
  actions,
  params,
  anamnesen,
  customers,
  dates,
  dateien,
  doctors,
  berufen,
  dokumentationen,
  fields,
  heilmittelverordnungen,
  krankenkassen,
  therapieberichte,
  people,
  handleSubmit,
  initializeForm,
  leistungenKK,
  leistungenPrivat,
  pristine,
  praxisstammdaten,
  dialogActions,
  currentServer,
}) => {
  const [berufenAutocompleteItems, setBerufenAutocompleteItems] = useState<Array<AutocompleteOption | undefined>>([])
  const [startedLoadingImage, setStartedLoadingImage] = useState<boolean>(false)
  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
  const [highlightedDates, setHighlightedDates] = useState<any>(null)
  const [highlightedHVO, setHighlightedHVO] = useState<any>(null)
  // ---
  // ---
  const [customerId, setCustomerId] = useState<number | null>(params.customer || null)

  const isCalendarPlan =
    currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name === 'calendar'

  const navigate = useNavigate()

  // set berufAutocompleteItems
  useEffect(() => {
    if (berufen) {
      const newBeruf: Array<AutocompleteOption> = Object.keys(berufen)
        .map((key) => berufen[key])
        .filter((item) => item.beruf !== null && fields.beruf?.value !== item.beruf)
        .map((arzt) => ({
          value: arzt.id,
          text: arzt.beruf,
        }))

      const uniqueFields = Array.from(new Set(newBeruf.map((ber) => ber.text))).map((txt) => {
        return newBeruf.find((ber) => ber.text === txt)
      })

      setBerufenAutocompleteItems(uniqueFields)
    }
  }, [berufen, fields])

  useEffect(() => {
    let newCustomerId: number | null = parseInt(params.customer, 10)
    if (isNaN(newCustomerId) || newCustomerId == null) {
      newCustomerId = null
    }
    setCustomerId(newCustomerId)
  }, [params.customer])
  // ---
  // ---
  const [customer, setCustomer] = useState<any>(null)
  useEffect(() => {
    let newCustomer = null
    if (customers && customerId != null) {
      newCustomer = customers[customerId]
    }
    setCustomer(newCustomer)
  }, [customers, customerId])
  // ---
  // ---
  const { serverHash } = params
  // ---
  // ---
  // load customer
  useEffect(() => {
    actions.patienten.loadCustomer(customerId, true)
  }, [customerId])
  // ---
  // loadPicture
  useEffect(() => {
    if (customerId != null && customers[customerId] && customers[customerId].bild && !startedLoadingImage) {
      actions.dateien.loadFiles([customers[customerId].bild], {
        sizes: [
          { width: 160, height: 240 },
          { width: 1600, height: 1200 },
        ],
      })
      setStartedLoadingImage(true)
    }
  }, [customers, customerId])
  // ---
  // ---
  const [imgSrc, setImgSrc] = useState<string | null>(null)
  const [imgFullSizeSrc, setImgFullSizeSrc] = useState<string | null>(null)
  useEffect(() => {
    let newImgSrc: string | null = null
    let newImgFullSizeSrc: string | null = null
    if (customer && dateien) {
      const newPictureAvailable =
        dateien[customer.bild] && dateien[customer.bild].resized && dateien[customer.bild].resized['160x240']
      newImgSrc = !!newPictureAvailable ? `data:image/png;base64,${dateien[customer.bild].resized['160x240']}` : null

      const newPictureFullSizeAvailable =
        dateien[customer.bild] && dateien[customer.bild].resized && dateien[customer.bild].resized['1600x1200']
      newImgFullSizeSrc = !!newPictureFullSizeAvailable
        ? `data:image/png;base64,${dateien[customer.bild].resized['1600x1200']}`
        : null
    }
    setImgSrc(newImgSrc)
    setImgFullSizeSrc(newImgFullSizeSrc)
  }, [dateien, customer])
  // ---
  // ---
  const [age, setAge] = useState<number | null>(null)
  useEffect(() => {
    const now = new Date()
    let newAge: number | null = null
    if (customer) {
      newAge = customer.geburtsdatum ? differenceInYears(now, customer.geburtsdatum) : null
    }
    setAge(newAge)
  }, [dateien, customer])
  // ---
  // ---
  const [hausarzt, setHausarzt] = useState<any>(null)
  const [facharzt, setFacharzt] = useState<any>(null)
  useEffect(() => {
    let newHausarzt: any = null
    let newFacharzt: any = null
    if (customer && doctors) {
      newHausarzt = doctors[customer.hausarzt]
      newFacharzt = doctors[customer.facharzt]
    }
    setHausarzt(newHausarzt)
    setFacharzt(newFacharzt)
  }, [doctors, customer])
  // ---
  // ---
  const [therapeut, setTherapeut] = useState<any>(null)
  useEffect(() => {
    let newTherapeut: number | null = null
    if (customer && people) {
      newTherapeut = people[customer?.regularTherapist]
    }
    setTherapeut(newTherapeut)
  }, [people, customer])
  // ---
  // ---
  const openLightbox = useCallback(() => {
    setLightboxOpen(true)
  }, [setLightboxOpen])

  const closeLightbox = useCallback(() => {
    setLightboxOpen(false)
  }, [setLightboxOpen])
  // ---
  const onSubmit = useCallback(
    async (data) => {
      await actions.patienten.updateCustomer(data)
      initializeForm(data)
    },
    [actions.patienten.updateCustomer, initializeForm],
  )
  // ---
  const setTermineHighlight = useCallback(
    (termine) => {
      setHighlightedDates(termine)
      return true
    },
    [setHighlightedDates],
  )
  // ---
  const setHVOHighlight = useCallback(
    (hvo) => {
      setHighlightedHVO(hvo)
      return true
    },
    [setHighlightedHVO],
  )

  if (
    !customer ||
    !anamnesen ||
    !dates ||
    !dateien ||
    !doctors ||
    !dokumentationen ||
    !heilmittelverordnungen ||
    !people ||
    !therapieberichte
  ) {
    return null
  }

  const termineTableCustomCols = [
    {
      header: 'Therapeut',
      data: (termin) => {
        const therapeut = R.find(R.propEq('id', termin.therapeut))(Object.values(people))
        const vorname = R.propOr('', 'vorname', therapeut)
        const nachname = R.toUpper(R.defaultTo('', R.head(R.propOr('', 'nachname', therapeut))))
        return [vorname, nachname].filter(Boolean).join(' ')
      },
    },
    {
      header: 'Leistungen',
      data: (termin) => {
        const hvoText = termin.hvoWirdNachgereicht ? 'HVO fehlt! ' : ''
        const hausbesuchIcon = termin.hausbesuch ? '\u2302 ' : ''
        return hausbesuchIcon + hvoText + buildLeistungenString(termin.leistungen, leistungenKK, leistungenPrivat)
      },
    },
    {
      header: 'Doku',
      data: (termin) => (dokumentationen[termin.dokumentation] ? '✓' : ''),
    },
    {
      header: 'Rechnung',
      data: (termin) => (termin.sevdeskinvoiceId ? <InsertDriveFileIcon style={{ fill: 'rgb(0, 160, 203)' }} /> : ''),
    },
  ]

  return (
    <StyledCustomerDetails>
      <IconHeading
        icon={<PersonIcon />}
        text={
          <span>
            Patientenkartei |&nbsp;
            <PatientText patient={customer} showGender />
            {!isReadOnly() && (
              <>
                (
                <Link to={`/${serverHash}/contacts/customers/${customerId}/edit`}>
                  zur Bearbeitung {/* for processing */}
                </Link>
                )
              </>
            )}
          </span>
        }
      />
      <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="container">
          {customer ? (
            <div className="fieldContainer">
              <div className="fieldGroup">
                {customer.verstorben && (
                  <CheckboxField readOnly label="Patient verstorben!" checked={customer.verstorben} />
                )}
                <img
                  style={{
                    display: 'block',
                    margin: '15px auto 0 auto',
                    cursor: !!imgSrc && !!imgFullSizeSrc ? 'pointer' : 'default',
                  }}
                  src={imgSrc || pictureBlankSrc}
                  onClick={!!imgSrc && !!imgFullSizeSrc ? openLightbox : undefined}
                />
                {lightboxOpen && (
                  <Lightbox
                    mainSrc={imgFullSizeSrc}
                    onCloseRequest={closeLightbox}
                    reactModalStyle={{ overlay: { zIndex: '1200' } }}
                  />
                )}
                &nbsp; Geschlecht: {genderIcons[customer.geschlecht]}
                <InputField disabled label="pododesk ID" value={customer.id} />
                <InputField disabled label="Patienten-Nr." value={customer.patientennummer} />
                <InputField disabled label="akad. Titel" value={customer.titel} />
                <InputField disabled label="Nachname" value={customer.nachname} />
                <InputField disabled label="Vorname" value={customer.vorname} />
                <InputField
                  disabled
                  label="Alter"
                  value={
                    customer.geburtsdatum ? `${age} (${format(customer.geburtsdatum, GERMAN_DATE_SHORT_YEAR_FNS)})` : ''
                  }
                />
                <AutocompleteField
                  disabled
                  value={customer.beruf}
                  label="Beruf"
                  className="autoComplete"
                  options={berufenAutocompleteItems}
                  autoSelect
                  freeSolo
                />
                <InputField disabled label="Straße" value={customer.strasse} />
                <InputField disabled label="Nr." value={customer.hausnummer} />
                <InputField disabled label="PLZ" value={customer.postleitzahl} />
                <InputField disabled label="Ort" value={customer.ort} />
                <InputField disabled label="Land" value={customer.land} />
                <InputField disabled label="empfohlen/geworben von" value={customer.empfehlung} />
                <InputField disabled label="bisherige Praxis" value={customer.bisherigePodologie} />
                <InputField disabled label="abgewandert zu" value={customer.abgewandertZu} />
              </div>
              <div className="fieldGroup">
                <InputField disabled label="Notiz" value={customer.notizen} multiline rows={''} />
                <InputField disabled label="Telefon" value={customer.telefon} />
                <InputField disabled label="Mobil" value={customer.mobil} />
                <InputField
                  disabled
                  label="Ländervorwahl"
                  value={customer.mobilCountry ? `+${customer.mobilCountry}` : ''}
                />
                <InputField disabled label="E-Mail" value={customer.email} />
                <div className="checkboxContainer">
                  <CheckboxField readOnly label="Terminerinnerung per SMS" checked={customer.smsReminder} />
                  <CheckboxField readOnly label="Terminerinnerung per Festnetz-Anruf" checked={customer.callReminder} />
                  <CheckboxField readOnly label="Terminerinnerung per E-Mail" checked={customer.emailErinnerung} />
                </div>
                {!!currentServer?.ownerPlanId &&
                  plan.find((p) => p.id === currentServer.ownerPlanId)?.name !== 'calendar' && (
                    <>
                      <InputField disabled label="Soziale Gemeinschaft" value={customer.sozialeGemeinschaft} />
                      <InputField
                        disabled
                        label="Krankenkasse"
                        value={
                          (krankenkassen[customer.krankenkasse] && krankenkassen[customer.krankenkasse].kuerzel) || ''
                        }
                      />
                      {fields.krankenkasse.value === 16 && (
                        <InputField disabled label="Sonstige Kostenträger" value={customer.otherInsurance} />
                      )}
                      <InputField disabled label="Versicherten-Nr." value={customer.versichertennummer} />
                    </>
                  )}
                <InputField
                  disabled
                  label="Hausarzt"
                  value={hausarzt ? [hausarzt.titel, hausarzt.nachname].filter(Boolean).join(' ') : ''}
                />
                <InputField
                  disabled
                  label="Facharzt"
                  value={facharzt ? [facharzt.titel, facharzt.nachname].filter(Boolean).join(' ') : ''}
                />
                <InputField disabled label="min. gewünschte Frequenz" value={customer.frequenz} />
                <InputField disabled label="max. gewünschte Frequenz" value={customer.maxFrequenz} />
                <InputField
                  disabled
                  label="Stammtherapeut"
                  value={therapeut ? [therapeut.vorname, therapeut.nachname].filter(Boolean).join(' ') : ''}
                />
              </div>
            </div>
          ) : null}
          <div className="listViewsContainer">
            {(!isCalendarPlan ||
              !!Object.keys(anamnesen)
                .map((k) => anamnesen[k])
                .filter((a) => a.patient === customerId).length) && (
              <>
                <label style={{ display: 'block', margin: '20px 0 10px' }}>
                  <span>
                    Anamnese
                    {!isReadOnly() && (
                      <>
                        &nbsp;|&nbsp;
                        <Link to={`/${serverHash}/anamnesis/${customerId}/01`}>
                          {!isCalendarPlan ? 'anzeigen/anlegen' : 'anzeigen'}
                          {/* display / create */}
                        </Link>
                      </>
                    )}
                  </span>
                </label>
                <AnamnesisListView
                  customer={customer}
                  anamnesis={anamnesen}
                  people={people}
                  dialogActions={dialogActions}
                />
              </>
            )}
            <label style={{ display: 'block', margin: '20px 0 10px' }}>
              <span>
                Termine
                {!isReadOnly() && (
                  <>
                    &nbsp;|&nbsp;
                    <AddNewLink
                      onClick={() => {
                        actions.selectTerminProps({
                          patient: customerId,
                        })

                        navigate(`/${serverHash}/calendar/free`)
                      }}
                    >
                      neu anlegen
                    </AddNewLink>
                  </>
                )}
              </span>
            </label>
            <DatesListView
              dialogActions={dialogActions}
              filter={{ patient: customerId }}
              columns={['datum', 'beginn', 'dauer'].concat(praxisstammdaten?.treatmentRoomFeature ? ['room'] : [])}
              highlightedDates={highlightedDates}
              setHVOHighlight={setHVOHighlight}
              customCols={termineTableCustomCols}
            />
            <label style={{ display: 'block', margin: '20px 0 10px' }}>
              abgesagte/verschobene Termine
              {/* canceled / postponed appointments */}
            </label>
            <DatesListView
              dialogActions={dialogActions}
              filter={{ patient: customerId, abgesagt: true }}
              columns={['datum', 'beginn']}
              customCols={[
                {
                  header: 'Absagedatum',
                  data: (termin) => (termin.absagedatum ? format(termin.absagedatum, GERMAN_DATE_TIME_FNS) : ''),
                },
                {
                  header: 'Begründung',
                  data: (termin) => termin.absagegrund,
                },
              ]}
              limit={3}
            />
            {(!isCalendarPlan ||
              !!Object.keys(heilmittelverordnungen)
                .map((k) => heilmittelverordnungen[k])
                .filter((h) => h.patient === customerId).length) && (
              <>
                <label style={{ display: 'block', margin: '20px 0 10px' }}>
                  <span>
                    Heilmittelverordnungen
                    {!isReadOnly() && !isCalendarPlan && (
                      <>
                        &nbsp;|&nbsp;
                        <Link to={`/${serverHash}/hvo/add/${customerId}`}>neu anlegen</Link>
                      </>
                    )}
                  </span>
                </label>
                <HVOListView
                  customer={customer}
                  doctors={doctors}
                  heilmittelverordnungen={heilmittelverordnungen}
                  highlightedHVO={highlightedHVO}
                  setTermineHighlight={setTermineHighlight}
                />
              </>
            )}
            {(!isCalendarPlan ||
              !!Object.keys(therapieberichte)
                .map((k) => therapieberichte[k])
                .filter((t) => t.patient === customerId).length) && (
              <>
                <label style={{ display: 'block', margin: '20px 0 10px' }}>
                  <span>
                    Therapieberichte
                    {!isReadOnly() && (
                      <>
                        &nbsp;|&nbsp;
                        <Link to={`/${serverHash}/reports/add/${customerId}`}>neu anlegen</Link>
                      </>
                    )}
                  </span>
                </label>
                <TherapyReportsListView
                  customer={customer}
                  doctors={doctors}
                  heilmittelverordnungen={heilmittelverordnungen}
                  people={people}
                  therapieberichte={therapieberichte}
                />
              </>
            )}
          </div>
        </div>

        <FileUploadWithLightbox
          {...fields.dateien}
          assignTo={{ id: customerId, entity: 'patienten' }}
          onFinish={() => {
            // Submit the form if a photo is added.
            setTimeout(handleSubmit(onSubmit), 0)
          }}
          readOnly={isReadOnly()}
        />
        <Button variant="contained" type="submit" color="secondary" fullWidth disabled={pristine}>
          Entfernen der Dateien speichern
          {/* Remove the files save */}
        </Button>
      </form>
    </StyledCustomerDetails>
  )
}

const mapStateToProps = (state, props) => ({
  anamnesen: state.entities.anamnesen,
  customers: state.entities.patienten,
  dates: state.entities.termine,
  dateien: state.entities.dateien,
  doctors: state.entities.aerzte,
  dokumentationen: state.entities.dokumentationen,
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  krankenkassen: state.krankenkassen,
  therapieberichte: state.entities.therapieberichte,
  people: state.entities.users,
  leistungenKK: state.leistungenKK,
  leistungenPrivat: state.entities.leistungenPrivat,
  initialValues: sGetInitialValues(state, props),
  berufen: state.entities.berufen,
  praxisstammdaten: praxisstammdatenSelector(state),
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    dateien: bindActionCreators(dateienActions, dispatch),
    patienten: bindActionCreators(patientenActions, dispatch),
    selectTerminProps: bindActionCreators(selectTerminProps, dispatch),
  },
})

const CustomerDetailsWithRedux = reduxForm(
  {
    form: 'customerUploadPhotosForm',
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(CustomerDetails))

export default (props) => {
  const params = useParams()
  return <CustomerDetailsWithRedux {...props} params={params} />
}
